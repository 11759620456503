// Any colors used throughout the app should be sourced from this file
// That way things remain consistent, and globally sourced from a singular location.
:root {
    // Default Color Scheme
    --default-bg: var(--bg-green-100);
    --default-text: var(--fg-light-80);
    --default-accent: var(--fg-dark-100);

    --bg-blue-100: #0f191a;
    --bg-blue-80: #253233;
    --bg-blue-60: #506566;
    --bg-blue-40: #7b9698;
    --bg-blue-20: #8fb0b2;
    --bg-blue-10: #a5c9cb;

    --bg-green-100: #197159;
    --bg-green-80: #30836c;
    --bg-green-60: #5da892;
    --bg-green-40: #73baa5;
    --bg-green-20: #88ccb8;
    --bg-green-10: #9fdfcb; 
    
    --fg-dark-100: #93613e;
    --fg-dark-80: #a17d54;
    --fg-dark-60: #b18e6a;
    --fg-dark-40: #cfb395;
    --fg-dark-20: #dfc5ab;
    --fg-dark-10: #eed8c1;
    
    --fg-light-100: #f2cf98;
    --fg-light-80: #f1ce97;
    --fg-light-60: #f5dcb4;
    --fg-light-40: #faead2;
    --fg-light-20: #fcf1e0;
    --fg-light-10: #fff8ef;
    
}
